/** @jsx jsx */
import { jsx, Themed } from "theme-ui"
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import { PrismicRichText } from "@prismicio/react"
import Layout from "../components/layout/layout"
import Metadata from "../components/metadata/metadata"
import Panel from "../components/panel/panel"
import SectionTitle from "../components/section-title/section-title"
import SquareList from "../components/list-square/list-square"
import { ButtonLink } from "../components/button/button"
import Link from "../components/link/link"

const LearningModule = ({ data }) => {
  if (!data) return null

  const module = data.prismicModule.data
  const library = data.prismicLearningLibrary.data
  const similarModules = data.similarModules.nodes.reduce((obj, lib) => {
    const mods = lib.data.modules.filter((mod) => {
      return mod.module.document?.data.category === module.category && mod.module.uid !== data.prismicModule.uid
    })
    mods.forEach(({ module }) => {
      obj[module.uid] = {
        libraryUid: lib.uid,
        ...module.document.data,
        uid: module.uid
      }
    })
    return obj
  }, {})
  const title = module.title.text

  const socialImage = module.main_image
  if (module.main_image.dimensions) {
    socialImage.width = module.main_image.dimensions.width
    socialImage.height = module.main_image.dimensions.height
  }

  return (
    <Layout>
      <Metadata
        title={`Learning Module: ${title}`}
        description={module.description.text}
        image={socialImage}
      />
      <Panel sx={{ py: ["4rem", "6rem", "8rem"] }}>
        <Link to={`/learning-libraries/${data.prismicLearningLibrary.uid}`} sx={{ color: 'inherit', textDecoration: 'none', gridColumn: "1 / -1", display: 'flex', gap: '2rem', alignItems: 'center', mb: ["4rem", "6rem", "8rem"] }}>
          <div sx={{ width: '8rem', flexShrink: 0 }}>
            <GatsbyImage
              image={library.icon.gatsbyImageData}
              alt={title}
              sx={{ border: 'solid 2px #eff1f2', borderRadius: '50%' }}
            />
          </div>
          <SectionTitle sx={{ mb: '0 !important' }}>
            {library.title.text}
          </SectionTitle>
        </Link>
        <Themed.h1 className="scaleText" sx={{ gridColumn: "1 / -1" }}>
          {title}
        </Themed.h1>
        <div
          sx={{
            gridColumnStart: ["1", "unset"],
            gridColumnEnd: ["-1", "span 4", "span 5"],
            mb: ["4rem", "unset"],
            fontSize: '1.4rem',
            lineHeight: 1.5,
            strong: {
              fontWeight: 600
            }
          }}
        >
          <PrismicRichText
            field={module.main_description.richText}
            components={{
              heading3: ({ key, node }) => <Themed.p key={key} sx={{ fontSize: '2rem', lineHeight: 1.6 }}>{node.text}</Themed.p>,
              heading4: ({ key, node }) => <Themed.h4 key={key} sx={{ mb: '1rem' }}>{node.text}</Themed.h4>,
              paragraph: (props) => <Themed.p key={props.key} {...props} />,
              list: (props) => <SquareList key={props.key} {...props} />
            }}
          />
          {module.case_study.url && (
            <ButtonLink to={module.case_study.url} target="_blank" download={`${title} - case study`}>Download case study</ButtonLink>
          )}
        </div>
        <div
          sx={{
            gridColumnStart: ["1", "unset"],
            gridColumnEnd: ["-1", "span 4", "span 7"],
            px: [null, "1rem", "4rem"],
            pt: [null, "8rem", 0],
            perspective: [null, "500px"],
          }}
        >
          {(module.youtube_video_id || module.vimeo_video_id) && (
            <div
              sx={{
                transform: [null, "rotateY(-4deg) skewY(1deg)"],
                borderRadius: "16px",
                boxShadow: "0 15px 20px 0 rgba(0, 0, 0, 0.16)",
                overflow: "hidden",
                position: "relative",
                zIndex: 0,
              }}
            >
              {module.youtube_video_id && (
                <lite-youtube videoid={module.youtube_video_id}></lite-youtube>
              )}
              {!module.youtube_video_id && module.vimeo_video_id && (
                <lite-vimeo videoid={module.vimeo_video_id}></lite-vimeo>
              )}
            </div>
          )}
          {!module.vimeo_video_id && !module.youtube_video_id && module.main_image.gatsbyImageData && (
            <GatsbyImage
              image={module.main_image.gatsbyImageData}
              alt={module.main_image.alt}
              sx={{
                transform: [null, "rotateY(-4deg) skewY(1deg)"],
              }}
            />
          )}
        </div>
        {module.learning_objectives && (
          <div
            sx={{
              gridColumnStart: ["1", "unset"],
              gridColumnEnd: ["-1", "span 4"],
              mb: ["4rem", "unset"],
              fontSize: '1.4rem',
              lineHeight: 1.5,
              strong: {
                fontWeight: 600
              }
            }}
          >
            <Themed.h2 sx={{ mb: '3rem' }}>Learning Objectives</Themed.h2>
            <PrismicRichText
              field={module.learning_objectives.richText}
              components={{
                paragraph: (props) => <Themed.p key={props.key} {...props} />,
                list: (props) => <SquareList key={props.key} {...props} />
              }}
            />
          </div>
        )}
        {module.skills && (
          <div
            sx={{
              gridColumnStart: ["1", "unset", "6"],
              gridColumnEnd: ["-1", "span 4"],
              mb: ["4rem", "unset"],
              fontSize: '1.4rem',
              lineHeight: 1.5,
              strong: {
                fontWeight: 600
              }
            }}
          >
            <Themed.h2 sx={{ mb: '3rem' }}>Skills</Themed.h2>
            <PrismicRichText
              field={module.skills.richText}
              components={{
                paragraph: (props) => <Themed.p key={props.key} {...props} />,
                list: (props) => <SquareList key={props.key} {...props} />
              }}
            />
          </div>
        )}
      </Panel>
      {Object.values(similarModules).length > 0 && (
        <Panel sx={{ py: ["4rem", "6rem", "8rem"] }}>
          <Themed.h2 sx={{ fontSize: ['2.4rem', null, '4rem'], gridColumn: "1 / -1", borderBottom: 'solid 3px #dee5e8', pb: '1rem', mb: '3rem' }}>
            Similar Experiences
          </Themed.h2>
          <ul sx={{
            gridColumn: "1 / -1",
            display: 'grid',
            gridTemplateColumns: ["repeat(1, 1fr)", 'repeat(2, 1fr)', "repeat(3, 1fr)"],
            gap: "4rem",
            m: 0,
            p: 0,
            listStyle: 'none'
          }}>
            {Object.values(similarModules).map((module) => {
              const { title, thumbnail_image, libraryUid, uid } = module ?? {}
              return (
                <Link
                  key={title?.text}
                  to={`/learning-libraries/${libraryUid}/${uid}`}
                  sx={{
                    borderRadius: "1.6rem",
                    bg: "bgGray",
                    px: ["2.4rem", null, "3rem"],
                    py: "3rem",
                    color: 'inherit',
                    textDecoration: 'none',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  {thumbnail_image?.gatsbyImageData && (
                    <div
                      sx={{
                        mb: '2rem',
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        aspectRatio: [null, "16 / 9"],
                      }}
                    >
                      <GatsbyImage
                        image={thumbnail_image.gatsbyImageData}
                        alt={title.text}
                      />
                    </div>
                  )}
                  <strong
                    sx={{
                      display: 'inline-block',
                      fontWeight: 600,
                      mt: 'auto',
                    }}
                  >
                    {title?.text}
                  </strong>
                </Link>
              )
            })}
          </ul>
        </Panel>
      )}
    </Layout>
  )
}
export default LearningModule

export const query = graphql`
  query LearningModuleQuery($libraryId: String, $moduleId: ID) {
    prismicLearningLibrary(id: { eq: $libraryId }) {
      uid
      data {
        title {
          text
        }
        icon {
          gatsbyImageData
        }
      }
    }
    prismicModule(prismicId: { eq: $moduleId }) {
      uid
      data {
        category
        title {
          text
        }
        description {
          text
        }
        main_description {
          richText
        }
        case_study {
          url
        }
        main_image {
          gatsbyImageData
          alt
          url
          dimensions {
            width
            height
          }
        }
        vimeo_video_id
        youtube_video_id
        learning_objectives {
          richText
        }
        skills {
          richText
        }
      }
    }
    similarModules: allPrismicLearningLibrary {
      nodes {
        uid
        data {
          modules {
            module {
              id
              uid
              document {
                ...on PrismicModule {
                  data {
                    title {
                      text
                    }
                    category
                    thumbnail_image {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
